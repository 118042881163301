<template>
    <div class="amap-page-container">
        <el-amap ref="map" vid="amapDemo" :amap-manager="amapManager" :center="center" :zoom="zoom" :plugin="plugin"
            class="amap-demo">
            <el-amap-marker v-for="(marker, index) in markerList" :key="marker.id" :position="marker.position"
                :content="getIcon(marker.type, marker.select, marker.remain)" :offset="[-21, -42]" :vid="index"
                :events="marker.events"></el-amap-marker>

            <div v-if="selectDevice.id" class="detail-modal-wrap">
                <div class="detail-modal-mask" @click="detailModalClick"></div>
                <div class="detail-modal">
                    <div class="title-wrap">
                        <div class="detail-title">{{ selectDevice.title }}</div>
                        <div class="detail-type" v-html="getTypeName(selectDevice.type)"></div>
                    </div>
                    <div v-if="selectedType !== 2 && selectedType !== 3 && !ifReturnList && selectDevice.describetion"
                        @click.stop="goToDetailPage(selectDevice)" class="detail-desc-wrap">
                        <img src="./assets/icon-location.png" class="icon-location" />
                        <p>{{ selectDevice.describetion }}</p>
                        <img src="./assets/icon-arrow.png" class="icon-arrow" />
                    </div>
                    <div class="detail-pic-wrap" v-if="selectDevice.headPic">
                        <img v-if="selectDevice.headPic" :src="selectDevice.headPic" />
                    </div>
                    <div v-if="selectDevice.parkingFlag == 1">
                        <div class="detail-parking-desc">是否免费：{{ selectDevice.ifMianfei ? '收费' : '免费' }}</div>
                        <div class="detail-parking-desc">总停车位：{{ selectDevice.total ? selectDevice.total : '未知' }}</div>
                        <!-- <div class="detail-parking-desc">剩余停车位：{{ selectDevice.free ? selectDevice.free : '未知' }}</div> -->
                    </div>
                    <div class="go-location" @click="gotoLocation(selectDevice)">到这里去</div>
                </div>
            </div>

        </el-amap>

        <div class="bottomLine" v-if="!ifReturnList">
            <div class="bottom-title">周边</div>
            <div class="bottom-icon-wrap">
                <div class="iconArea" :class="selectedType === 1 ? 'iconAreaSelect' : ''" @click="changeSelectType(1)">
                    <img src="./assets/icon1.png" />
                    <span>景点</span>
                </div>
                <div class="iconArea" :class="selectedType === 4 ? 'iconAreaSelect' : ''" @click="changeSelectType(4)">
                    <img src="./assets/icon2.png" />
                    <span>美食</span>
                </div>
                <div class="iconArea" :class="selectedType === 5 ? 'iconAreaSelect' : ''" @click="changeSelectType(5)">
                    <img src="./assets/icon3.png" />
                    <span>酒店</span>
                </div>
                <div class="iconArea" :class="selectedType === 6 ? 'iconAreaSelect' : ''" @click="changeSelectType(6)">
                    <img src="./assets/icon4.png" />
                    <span>购物</span>
                </div>
                <div class="iconArea" :class="selectedType === 11 ? 'iconAreaSelect' : ''"
                    @click="changeSelectType(11)">
                    <img src="./assets/icon5.png" />
                    <span>娱乐</span>
                </div>

            </div>
        </div>

        <div class="rightLine" v-if="!ifReturnList">
            <div class="right-item" :class="selectedType === 2 ? 'right-item-select' : ''" @click="changeSelectType(2)">
                <img src="./assets/icon6.png" v-if="selectedType !== 2" />
                <img src="./assets/select-icon6.png" v-else />
                <span>停车场</span>
            </div>
            <div class="right-item" :class="selectedType === 3 ? 'right-item-select' : ''" @click="changeSelectType(3)">
                <img src="./assets/icon7.png" v-if="selectedType !== 3" />
                <img src="./assets/select-icon7.png" v-else />
                <span>公厕</span>
            </div>
            <div class="right-item" :class="selectedType === 12 ? 'right-item-select' : ''"
                @click="changeSelectType(12)">
                <img src="./assets/icon8.png" v-if="selectedType !== 12" />
                <img src="./assets/select-icon8.png" v-else />
                <span>加油站</span>
            </div>
            <div class="right-item" :class="selectedType === 13 ? 'right-item-select' : ''"
                @click="changeSelectType(13)">
                <img src="./assets/chargingPile.png" v-if="selectedType !== 13" />
                <img src="./assets/chargingPile2.png" v-else />
                <span>充电桩</span>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { Notify } from 'vant';
// NPM 方式
import VueAMap from 'vue-amap';
VueAMap.initAMapApiLoader({
    key: 'bf8e7cb2ae43312d6b6dafae4efeb62d',
    plugin: [
        'AMap.Autocomplete',
        'AMap.PlaceSearch',
        'AMap.Scale',
        'AMap.OverView',
        'AMap.ToolBar',
        'AMap.MapType',
        'AMap.PolyEditor',
        'AMap.CircleEditor',
        'AMap.Geolocation',
        'AMap.Driving',
        'AMap.ImageLayer',
    ],
    uiVersion: '1.0.11', // 版本号
    v: '1.4.4',
});
import { AMapManager } from 'vue-amap';
let amapManager = new AMapManager();
let trafficLayer = null;

export default {
    data: function () {
        const this_ = this;
        const contentId = this_.$route.query.contentId || null;
        const topicSymbol = this_.$route.query.topicSymbol || null;
        const latitude = this_.$route.query.latitude || null;
        const longitude = this_.$route.query.longitude || null;
        const tenantId = this_.$route.query.tenantId || null;
        const ifFengkou = this_.$route.query.ifFengkou || null;
        const position = [121.584478, 29.924373];
        const title = '外漕村';
        return {
            latitude,
            longitude,
            ifFengkou,
            // 当前定位点
            currentLocation: [],
            // 选中设备详情
            selectDevice: {},
            contentId: contentId,
            tenantId: tenantId,
            topicSymbol: topicSymbol,
            ifReturnList: contentId ? true : false,
            // 选中的类型
            selectedType: 1,
            amapManager,
            zoom: 12,
            center: position,
            plugin: [],
            markers: [
                {
                    position: position,
                },
            ],
            allMarkers: [
                {
                    position: [119.4333, 29.546884],
                    type: 1,
                    name: '景点',
                },
                {
                    position: [119.6333, 29.53884],
                    type: 2,
                    name: '停车场',
                },
                {
                    position: [119.64545, 29.534884],
                    type: 3,
                    name: '卫生间',
                },
                {
                    position: [119.343232, 29.46884],
                    type: 4,
                    name: '餐饮美食',
                },
                {
                    position: [119.49885, 29.596884],
                    type: 5,
                    name: '酒店名宿',
                },
                {
                    position: [119.4783, 29.5767484],
                    type: 6,
                    name: '购物场所',
                },
            ],
            markerList: [],
            location1: require('./assets/marker1.png'),
            location2: require('./assets/marker6.png'),
            location3: require('./assets/marker7.png'),
            location4: require('./assets/marker2.png'),
            location5: require('./assets/marker3.png'),
            location6: require('./assets/marker4.png'),
            location11: require('./assets/marker5.png'),
            location12: require('./assets/marker8.png'),
            location13: require('./assets/chargingPile3.png'),
            locationSelected1: require('./assets/select-marker1.png'),
            locationSelected2: require('./assets/select-marker6.png'),
            locationSelected3: require('./assets/select-marker7.png'),
            locationSelected4: require('./assets/select-marker2.png'),
            locationSelected5: require('./assets/select-marker3.png'),
            locationSelected6: require('./assets/select-marker4.png'),
            locationSelected11: require('./assets/select-marker5.png'),
            locationSelected12: require('./assets/select-marker8.png'),
            locationSelected13: require('./assets/chargingPile4.png'),
            redCircle: require('./img/redCircle.png'),
            bigBlackCircle: require('./img/bigBlackCircle.png'),
            smallBlackCircle: require('./img/smallBlackCircle.png'),
            detailIconNew: require('./img/detail.png'),
            daohangIcon: require('./img/daohang.png'),
            detailIcon: require('./img/detailIcon.png'),
            tringle: require('./img/tringle.png'),
            texts: [
                {
                    position: position,
                    text: title,
                    offset: [0, -50],
                },
            ],
            textList: [],
            textContent: '',
            textOffset: [0, -36],
        };
    },
    mounted() {
        // 添加图层
        //（旧图）
        // https://meiyun-mng.oss-cn-hangzhou.aliyuncs.com/upload/bigscreen/img/%E6%AD%A3%E5%BC%8F%E5%BA%93/%E5%A4%96%E6%BC%95/%E5%A4%96%E6%A7%BD%E6%9D%91.png
        this.$nextTick(function () {
            const timer = setTimeout(function () {
                let o = amapManager.getMap();
                const waicaoPoint1 = new AMap.LngLat(121.575133, 29.920471);
                const waicaoPoint2 = new AMap.LngLat(121.5952, 29.937765);
                var imageLayer = new AMap.ImageLayer({
                    bounds: new AMap.Bounds(waicaoPoint1, waicaoPoint2),
                    url:
                        'https://meiyun-mng.oss-cn-hangzhou.aliyuncs.com/upload/bigscreen/img/%E6%AD%A3%E5%BC%8F%E5%BA%93/%E5%A4%96%E6%BC%95/%E6%89%8B%E7%BB%98%E5%9C%B0%E5%9B%BE/%E5%A4%96%E6%A7%BD%E6%9D%91.png', // 图片 Url
                    zIndex: 12,
                    zooms: [3, 18], // 设置可见级别，[最小级别，最大级别]
                });
                o.add(imageLayer);
                clearTimeout(timer);
                trafficLayer = new AMap.TileLayer.Traffic({
                    zIndex: 12
                });
                trafficLayer.setMap(o);
                trafficLayer.hide();
            }, 1500);
        });
    },
    created() {
        if (this.ifReturnList) {
            this.getOneMarker();
        } else {
            this.pushMarkers();
            //   获取区域中心点
            //   this.getCenterByCode();
        }
    },
    methods: {
        getCenterByCode() {
            const this_ = this;
            const params = {
                keywords: this.tenantId.slice(0, 6),
                key: 'b730f143b581509e91e4e00e70d11381',
            };
            axios.defaults.headers = {};
            axios
                .get('https://restapi.amap.com/v3/config/district', { params: params })
                .then((res) => {
                    let center = res.data.districts[0].center.split(',');
                    console.log(center);
                    const centerTrue = [];
                    center.forEach((item) => {
                        centerTrue.push(parseFloat(item));
                    });
                    this_.center = [...centerTrue];
                });
        },
        getOneMarker() {
            const this_ = this;
            const contentId = this_.contentId;
            const topicSymbol = this_.topicSymbol;
            const tenantId = this_.tenantId;
            this_.$api
                .getContentDetail({
                    contentId: contentId || '',
                    topicSymbol: topicSymbol,
                    tenantId: tenantId,
                })
                .then((res) => {
                    if (res.data.code === 10000) {
                        const item = res.data.data;
                        // 放到点中去
                        const markers = [];
                        if (topicSymbol === 'min-souvenir-detail') {
                            const marker = {
                                id: item.id,
                                topicSymbol: topicSymbol,
                                position: item.content.purchase_point,
                                type: this_.getType(topicSymbol),
                                name: item.content.purchase_place,
                                select: true,
                            };
                            markers.push(marker);
                            this_.markerList = markers;
                            // 更新地图中心点
                            this_.center = item.content.purchase_point;
                            // 设置景点文字
                            this_.lookText(marker);
                        } else {
                            const marker = {
                                id: item.id,
                                topicSymbol: topicSymbol,
                                position: item.content.location,
                                type: this_.getType(topicSymbol),
                                name: item.title,
                                select: true,
                            };
                            markers.push(marker);
                            this_.markerList = markers;
                            // 更新地图中心点
                            this_.center = item.content.location;
                            this_.zoom = 13;
                            // 设置景点文字
                            this_.lookText(marker);
                        }
                    } else {
                        Notify({ type: 'warning', message: res.data.msg });
                    }
                });
        },
        pushMarkers() {
            let this_ = this;
            let markers = [];
            let index = 0;
            const type = this_.selectedType;
            const topicSymbol = this_.getTopicSymbol(type);
            let parkingList = [];
            topicSymbol.forEach((topicSymbolItem) => {
                this_.$api
                    .getContentByPage({
                        page: 1,
                        size: 1000,
                        topicSymbol: topicSymbolItem,
                        tenantId: this_.tenantId,
                    })
                    .then((res) => {
                        if (res.data.code === 10000) {
                            const list = res.data.data.records;
                            if (list.length === 0) {
                                this_.getCenterByCode();
                                return;
                            }
                            // 获取经度数组和纬度数组
                            let jingduList = 0;
                            let weiduList = 0;
                            list.forEach((item) => {
                                jingduList += parseFloat(item.content.location[0]) || 0;
                                weiduList += parseFloat(item.content.location[1]) || 0;
                                const marker = {
                                    id: item.id,
                                    topicSymbol: topicSymbolItem,
                                    position: item.content.location,
                                    type: this_.getType(topicSymbolItem),
                                    name: item.title,
                                    select: false,
                                };
                                if (topicSymbol == 'min-park-lot-list') {
                                    marker.count = item.content.parking_amount;
                                    marker.remain = null;
                                    marker.ifMianfei =
                                        item.content.parking_id === 1 ? true : false;
                                }
                                marker.events = {
                                    click: () => {
                                        this_.lookText(marker);
                                    },
                                };

                                markers.push(marker);
                            });
                            jingduList = parseFloat(jingduList / list.length).toFixed(6);
                            weiduList = parseFloat(weiduList / list.length).toFixed(6);
                            this_.center = [jingduList, weiduList];
                            console.log(this_.center);
                            this_.zoom = 13;
                        } else {
                            Notify({ type: 'warning', message: res.data.msg });
                        }
                    });
            });
            this_.markerList = markers;
            console.log(this_.markerList);
        },
        changeSelectType(type) {
            trafficLayer && trafficLayer.hide();
            this.clearLocation();
            this.selectDevice = {};
            this.textList = [];
            this.textContent = '';
            this.selectedType = type;
            this.pushMarkers();
            this.zoom = 12;
        },
        getType(topicSymbol) {
            let type = '';
            switch (topicSymbol) {
                case 'min-attractions-detail':
                    type = 1;
                    break;
                case 'min-park-lot-list':
                    type = 2;
                    break;
                case 'min-toilet-list':
                    type = 3;
                    break;
                case 'min-catering-detail':
                    type = 4;
                    break;
                case 'min-food-detail':
                    type = 4;
                    break;
                case 'min-hotel-detail':
                    type = 5;
                    break;
                case 'min-shop-detail':
                    type = 6;
                    break;
                case 'min-souvenir-detail':
                    type = 7;
                    break;
                case 'min-entertainment-detail':
                    type = 11;
                    break;
                case 'min-gasStation-detail':
                    type = 12;
                    break;
                case 'charging-station-detail':
                    type = 13;
                    break;
            }
            return type;
        },
        getTopicSymbol(type) {
            let topicSymbol = '';
            switch (type) {
                case 1:
                    topicSymbol = ['min-attractions-detail'];
                    break;
                case 2:
                    topicSymbol = ['min-park-lot-list'];
                    break;
                case 3:
                    topicSymbol = ['min-toilet-list'];
                    break;
                case 4:
                    topicSymbol = ['min-catering-detail', 'min-food-detail'];
                    break;
                case 5:
                    topicSymbol = ['min-hotel-detail'];
                    break;
                case 6:
                    topicSymbol = ['min-shop-detail'];
                    break;
                case 11:
                    topicSymbol = ['min-entertainment-detail'];
                    break;
                case 12:
                    topicSymbol = ['min-gasStation-detail'];
                    break;
                case 13:
                    topicSymbol = ['charging-station-detail'];
                    break;
            }
            return topicSymbol;
        },
        getTypeName(type) {
            let name = '';
            switch (type) {
                case 1:
                    name = '景点'
                    break;
                case 2:
                    name = '停车场'
                    break;
                case 3:
                    name = '公厕'
                    break;
                case 4:
                    name = '餐饮美食'
                    break;
                case 5:
                    name = '酒店名宿'
                    break;
                case 6:
                    name = '购物场所'
                    break;
                case 11:
                    name = '文明实践'
                    break;
                case 12:
                    name = '加油站'
                    break;
                case 13:
                    name = '充电桩'
                    break;
            }
            return name;
        },
        // 获取背景图
        getBackIconUrl(amount) {
            if (!amount) {
                return null;
            }
            console.log(amount);
            amount = Number(amount);
            if (amount == 0) {
                return './img/redCircle.png';
            }
            if (amount > 0 && amount < 100) {
                return this.smallBlackCircle;
            }
            if (amount > 100) {
                return './img/bigBlackCircle.png';
            }
        },
        getIcon(type, select, amount) {
            const this_ = this;
            let pic = '';
            switch (type) {
                case 1:
                    if (select) {
                        pic = `<img src=${this_.locationSelected1} style='width:42px;height:42px'/>`;
                    } else {
                        pic = `<img src=${this_.location1} style='width:42px;height:42px'/>`;
                    }
                    break;
                case 2:
                    if (amount) {
                        if (select) {
                            pic = `<div style='width: 22px;height: 43px;display: flex;flex-direction: column;align-items: center;justify-content: flex-end;'><div v-if=${amount}  style='width:100%;height:17.5px;display: flex;justify-content: center;align-items: flex-start;position: relative;'><img src=${amount == 0
                                ? this_.redCircle
                                : amount > 99
                                    ? this_.bigBlackCircle
                                    : this_.smallBlackCircle
                                } style='width:${String(amount).length == 3 ? '15px' : '12px'
                                };height:${String(amount).length == 3 ? '17px' : '14px'
                                };position: absolute;top: ${String(amount).length == 3 ? '0px' : '4px'
                                };left: ${String(amount).length == 3 ? '3.5px' : '5px'
                                };' /><span style='font-size: 7px;color:#FFFFFF;position: absolute;top: ${String(amount).length == 3 ? '-1px' : '1px'
                                };left: ${String(amount).length == 3
                                    ? '6.4px'
                                    : String(amount).length == 2
                                        ? '7.4px'
                                        : '9px'
                                }'>${amount}</span></div><img src=${this_.locationSelected2
                                } style='width:42px;height:42px'/></div>`;
                        } else {
                            pic = `<div style='width: 22px;height: 43px;display: flex;flex-direction: column;align-items: center;justify-content: flex-end;'><div v-if=${amount}  style='width:100%;height:17.5px;display: flex;justify-content: center;align-items: flex-start;position: relative;'><img src=${amount == 0
                                ? this_.redCircle
                                : amount > 99
                                    ? this_.bigBlackCircle
                                    : this_.smallBlackCircle
                                } style='width:${String(amount).length == 3 ? '15px' : '12px'
                                };height:${String(amount).length == 3 ? '17px' : '14px'
                                };position: absolute;top: ${String(amount).length == 3 ? '0px' : '4px'
                                };left: ${String(amount).length == 3 ? '3.5px' : '5px'
                                };' /><span style='font-size: 7px;color:#FFFFFF;position: absolute;top: ${String(amount).length == 3 ? '-1px' : '1px'
                                };left:${String(amount).length == 3
                                    ? '6.4px'
                                    : String(amount).length == 2
                                        ? '7.4px'
                                        : '9px'
                                }'>${amount}</span></div><img src=${this_.location2
                                } style='width:42px;height:42px'/></div>`;
                        }
                    } else {
                        if (select) {
                            pic = `<img src=${this_.locationSelected2} style='width:42px;height:42px'/>`;
                        } else {
                            pic = `<img src=${this_.location2} style='width:42px;height:42px'/>`;
                        }
                    }

                    break;
                case 3:
                    if (select) {
                        pic = `<img src=${this_.locationSelected3} style='width:42px;height:42px'/>`;
                    } else {
                        pic = `<img src=${this_.location3} style='width:42px;height:42px'/>`;
                    }
                    break;
                case 4:
                    if (select) {
                        pic = `<img src=${this_.locationSelected4} style='width:42px;height:42px'/>`;
                    } else {
                        pic = `<img src=${this_.location4} style='width:42px;height:42px'/>`;
                    }
                    break;
                case 5:
                    if (select) {
                        pic = `<img src=${this_.locationSelected5} style='width:42px;height:42px'/>`;
                    } else {
                        pic = `<img src=${this_.location5} style='width:42px;height:42px'/>`;
                    }
                    break;
                case 6:
                    if (select) {
                        pic = `<img src=${this_.locationSelected6} style='width:42px;height:42px'/>`;
                    } else {
                        pic = `<img src=${this_.location6} style='width:42px;height:42px'/>`;
                    }
                    break;
                case 11:
                    if (select) {
                        pic = `<img src=${this_.locationSelected11} style='width:42px;height:42px'/>`;
                    } else {
                        pic = `<img src=${this_.location11} style='width:42px;height:42px'/>`;
                    }
                    break;
                case 12:
                    if (select) {
                        pic = `<img src=${this_.locationSelected12} style='width:42px;height:42px'/>`;
                    } else {
                        pic = `<img src=${this_.location12} style='width:42px;height:42px'/>`;
                    }
                    break;
                case 13:
                    if (select) {
                        pic = `<img src=${this_.locationSelected13} style='width:42px;height:42px'/>`;
                    } else {
                        pic = `<img src=${this_.location13} style='width:42px;height:42px'/>`;
                    }
                    break;
            }
            return pic;
        },
        lookText(marker) {
            const this_ = this;
            const list = this_.markerList;
            list.forEach((item) => {
                if (item.id == marker.id) {
                    item.select = true;
                } else {
                    item.select = false;
                }
            });
            this_.markerList = list;

            this_.$api
                .getContentDetail({
                    contentId: marker.id || '',
                    topicSymbol: marker.topicSymbol,
                    tenantId: this_.tenantId,
                })
                .then((res) => {
                    if (res.data.code === 10000) {
                        const item = res.data.data;
                        const detailItem = {
                            ...item.content,
                            type: marker.type
                        };
                        detailItem.id = item.id;
                        detailItem.topicSymbol = marker.topicSymbol;
                        // 纪念品字段有点特殊
                        if (marker.topicSymbol === 'min-souvenir-detail') {
                            detailItem.headPic = item.content.title_img;
                            detailItem.title = item.content.purchase_place;
                            detailItem.location = item.content.purchase_point;
                        } else {
                            detailItem.headPic = item.content.first_figure;
                            detailItem.title = item.title;
                            detailItem.location = item.content.location;
                        }

                        if (marker.topicSymbol == 'min-park-lot-list') {
                            detailItem.parkingFlag = 1;
                            detailItem.ifMianfei = marker.ifMianfei;
                            detailItem.total = marker.count;
                            detailItem.free = marker.remain;
                        } else {
                            detailItem.parkingFlag = 0;
                        }
                        // if(item.id=='1501730089888104449'){
                        //     detailItem.describetion = '免费WIFI、临时休息、免费开水、免费手机充电、免费阅读、免费资料打印、洗手间免费开放、应急药品、代办代跑。联系方式：0578 —5533000';
                        // }else if(item.id=='1501730343828045826'){
                        //     detailItem.describetion = '纠纷调解、免费法律咨询。联系方式：0578—5533085'
                        // }else if(item.id=='1501730708187234305'){
                        //     detailItem.describetion = '图书借阅、洗手间免费开放、免费开水。联系方式：0578—5533058'
                        // }else if(item.id=='1501730944339132418'){
                        //     detailItem.describetion = '健康咨询、免费测血压血糖、应急药品。联系方式：0578—5151207'
                        // }else if(item.id=='1501731358841225217'){
                        //     detailItem.describetion = '法律咨询、洗手间免费开放、免费开水。联系方式：0578—5151109'
                        // }else if(item.id=='1501731837755244546'){
                        //     detailItem.describetion = '免费借用雨伞。联系方式：0578—5151155'
                        // }else if(item.id=='1501732182145351681'){
                        //     detailItem.describetion = '免费WIFI、免费资料打印、免费新能源车充电。联系方式：0578—515533'
                        // }else if(item.id=='1501732479945129985'){
                        //     detailItem.describetion = '浙江省非物质文化遗产——瓯江帆船技艺展示。联系方式：15990433096'
                        // }else if(item.id=='1501732743771045890'){
                        //     detailItem.describetion = '免费开水。联系方式：13754263827'
                        // }else if(item.id=='1501732984138219521'){
                        //     detailItem.describetion = '快递代发。联系方式：15988033332'
                        // }
                        this_.selectDevice = detailItem;
                    } else {
                        Notify({ type: 'warning', message: res.data.msg });
                    }
                });
            // this_.clearLocation();
        },
        goToDetailPage(marker) {
            const that = this;
            console.log(marker);
            const url = that.ifFengkou ? that.getFengkouPageUrl(marker.topicSymbol) : that.getPageUrl(marker.topicSymbol);
            if (!that.ifReturnList) {
                wx.miniProgram.navigateTo({
                    url: `${url}?id=${marker.id}`,
                    events: {
                        // 为指定事件添加一个监听器，获取被打开页面传送到当前页面的数据
                        acceptDataFromOpenedPage: function (data) {
                            console.log(data);
                        },
                        someEvent: function (data) {
                            console.log(data);
                        },
                    },
                    success: function (res) {
                        // 通过eventChannel向被打开页面传送数据
                        console.log(res);
                    },
                });
                // wx.miniProgram.postMessage({ data: marker });
            }
        },
        // 规划路径
        gotoLocation(selectDevice) {
            const this_ = this;
            const latitude = this_.latitude;
            const longitude = this_.longitude;
            let o = amapManager.getMap();
            let driving = new AMap.Driving({
                map: o,
                // 驾车路线规划策略，AMap.DrivingPolicy.LEAST_TIME是最快捷模式
                policy: AMap.DrivingPolicy.LEAST_TIME,
            });

            if (!longitude) {
                Notify({ type: 'warning', message: '未授权定位或定位失败' });
                return false;
            }
            // 打开手机app的规划路线
            wx.miniProgram.navigateTo({
                url: `/wenLvPages1/mapViewChoose/mapViewChoose?longitude=${selectDevice.location[0]}&latitude=${selectDevice.location[1]}&title=${selectDevice.title}`,
            });
        },
        // 清除路径
        clearLocation() {
            let map = amapManager.getMap();
            map.clearMap();
        },
        // 获取页面
        getPageUrl(topicSymbol) {
            let url = '';
            switch (topicSymbol) {
                case 'min-attractions-detail':
                    url = '/pages/theGreatView/greatViewDetail/greatViewDetail';
                    break;
                case 'min-catering-detail':
                    url = '/pages/restaurantAndFood/restaurantDetail/restaurantDetail';
                    break;
                case 'min-food-detail':
                    url = '/pages/restaurantAndFood/foodDetail/foodDetail';
                    break;
                case 'min-hotel-detail':
                    url = '/pages/hotel/hotelDetail/hotelDetail';
                    break;
                case 'min-shop-detail':
                    url = '/pages/shoppingMall/shoppingMallDetail/shoppingMallDetail';
                    break;
            }
            return url;
        },
        // 汾口镇的详情页
        getFengkouPageUrl(topicSymbol) {
            let url = '';
            switch (topicSymbol) {
                case 'min-attractions-detail':
                    url = '/pages/trevalPages/greatViewDetail/greatViewDetail';
                    break;
                case 'min-catering-detail':
                    url = '/pages/trevalPages/restaurantDetail/restaurantDetail';
                    break;
                case 'min-food-detail':
                    url = '/pages/trevalPages/foodDetail/foodDetail';
                    break;
                case 'min-hotel-detail':
                    url = '/pages/trevalPages/hotelDetail/hotelDetail';
                    break;
                case 'min-shop-detail':
                    url = '/pages/trevalPages/shoppingMallDetail/shoppingMallDetail';
                    break;
            }
            return url;
        },
        goBack() {
            this.$router.go(-1);
        },
        detailModalClick() {
            this.selectDevice = {}
        }
    }
};
</script>

<style lang="scss" scoped>
$px: 1rem/37.5;

.amap-page-container {
    height: 100%;
    position: relative;
}

.amap-demo {
    height: 100%;
}

.bottonArea {
    position: absolute;
    bottom: 1rem;
    left: 0.8rem;
    border-radius: 0.16rem;
}

.bottomLine {
    height: 168 *$px;
    background: #FFFFFF;
    box-shadow: 0px 2*$px 4*$px 0px rgba(0, 0, 0, 0.3);
    border-radius: 8*$px 8*$px 0px 0px;
    width: 100%;
    background-color: #ffffff;
    position: absolute;
    bottom: 0rem;
    left: 0rem;
}

.bottom-title {
    font-size: 15*$px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 21*$px;
    padding: 16*$px 13*$px 12*$px;
}

.bottom-icon-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16*$px;
}

.iconArea {
    height: 95*$px;
    width: 63*$px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    >img {
        display: block;
        width: 39*$px;
        height: 39*$px;
    }

    >span {
        font-size: 13*$px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 18*$px;
        padding-top: 5*$px ;
    }
}

.iconAreaSelect {
    background: #E1F4FF;
    border-radius: 16*$px;

    >span {
        font-size: 14*$px;
        font-weight: bold;
        color: #333;
    }
}

.selectedFont {
    margin-top: 0.1rem;
    color: #1677ff;
}

.noselectFont {
    margin-top: 0.1rem;
    color: #999999;
}

.detailTab {
    width: 8.93333rem;
    height: 1.38667rem;
    position: absolute;
    left: 0.53333rem;
    bottom: 2.1333rem;
    background-color: #ffffff;
    border-radius: 0.08rem;
    box-shadow: 0px 0.08rem 0.16rem 0px rgba(204, 206, 210, 0.5);
    z-index: 99;
}

.detailTabParking {
    width: 8.93333rem;
    height: 1.38667rem;
    position: absolute;
    left: 0.53333rem;
    bottom: 2.1333rem;
    background-color: #ffffff;
    border-radius: 0.08rem;
    z-index: 99;
    box-shadow: 0px 0.08rem 0.16rem 0px rgba(204, 206, 210, 0.5);
}

.parkingTab {
    height: 100%;
    width: 7.5rem;
}

.parkingTabTitleLine {
    height: 20 * $px;
    margin-top: 6 * $px;
    margin-left: 10 * $px;
    font-size: 14 * $px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.parkingTabFont {
    margin-left: 0.24rem;
    height: 16 * $px;
    width: 30 * $px;
    color: #ff7e48;
    border: #ff7e48 0.75 * $px solid;
    box-sizing: border-box;
    border-radius: 6 * $px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.smallSize {
    font-size: 12px;
    -webkit-transform-origin-x: 0;
    transform: scale(0.75);
    -webkit-transform: scale(0.75);
}

.oSmallSize {
    font-size: 9px;
}

.selectDeviceImg {
    position: absolute;
    height: 52 * $px;
    width: 52 * $px;
    left: 10 * $px;
    bottom: 10 * $px;
    box-shadow: 0px 1 * $px 2 * $px 0px rgba(243, 242, 242, 1);
}

.selectDeviceTitle {
    margin-left: 71 * $px;
    color: #3f3f3f;
    font-size: 14 * $px;
    height: 100%;
    width: 140 * $px;
    display: flex;
    align-items: center;
    text-overflow: ellipsis;
}

.checkDetail {
    font-size: 10 * $px;
    color: #c0c0c0;
    white-space: nowrap;
    width: 100%;
    text-align: center;
    overflow-x: hidden;
}

.checkDetailAndDaohang {
    height: 100%;
    width: 50 * $px;
    display: flex;
    margin-right: 8 * $px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.checkDetailArea {
    position: absolute;
    top: 0rem;
    right: 1.62rem;
}

.checkDaohangArea {
    position: absolute;
    top: 0.15rem;
    right: 0.16rem;
}

.parkingIconArea {
    width: 22px;
    height: 33px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}

.small-font {
    font-size: 14px;
    -webkit-transform-origin-x: 0;
    -webkit-transform: scale(0.5);
}

.smallsize-font {
    font-size: 7px;
}

.fontDisplay {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
}

.parkingDetailLine {
    height: 14 * $px;
    margin-left: 10 * $px;
    color: #989898;
    font-size: 10 * $px;
}

.detailTabArea {
    width: 120 * $px;
    height: 100%;
    display: flex;
    justify-content: flex-end;
}

.detailTabOutImg {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.DetailImg {
    width: 17 * $px;
    height: 17 * $px;
}

.parkingTab-left {
    width: 260 * $px;
    height: 100%;
}

.parkingTab-right {
    width: 70 * $px;
    height: 100%;
    display: flex;
    justify-content: flex-end;
}

.rightLine {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 16*$px;
    top: 24*$px;
    background-color: #ffffff;
    width: 40*$px;
    box-shadow: 0*$px 2*$px 4*$px 0*$px rgba(0, 0, 0, 0.2);
    border-radius: 4*$px;
    z-index: 99;

    .right-item {
        padding: 8*$px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;

        &::after {
            content: "";
            width: 32*$px;
            height: 1px;
            background: #D8D8D8;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
        }

        &:nth-child(4) {
            &::after {
                display: none;
            }
        }

        >img {
            display: block;
            width: 22*$px;
            height: 22*$px;
        }

        >span {
            font-size: 10*$px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #2F3440;
            line-height: 14*$px;
            padding-top: 2*$px;
        }
    }

    .right-item-select {
        background-color: #419EFF;

        &::after {
            display: none;
        }

        >span {
            color: #FFFFFF;
        }
    }
}

.detail-modal-wrap {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    // bottom:0;
    left: 0;
    z-index: 90;

    .detail-modal-mask {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        // bottom:0;
        left: 0;
        z-index: 80;
    }
}

.detail-modal {
    box-shadow: 0*$px 2*$px 4*$px 0 rgba(0, 0, 0, 0.3);
    border-radius: 8*$px 8*$px 0 0;
    width: 375*$px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 99;
    background-color: #fff;

    .title-wrap {
        margin: 0 16*$px 16*$px;
        padding: 16*$px 0;
        border-bottom: 1px solid #eee;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .detail-title {
            flex: 1;
            font-size: 18*$px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 25*$px;
            margin-left: 8*$px;
            margin-right: 12*$px;
        }

        .detail-type {
            min-width: 28*$px;
            padding: 0 16*$px;
            height: 24*$px;
            background: linear-gradient(163deg, #FFBA6C 0%, #FF843A 100%);
            border-radius: 13*$px;
            font-size: 11*$px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 16*$px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 8*$px;
        }
    }

    .detail-desc-wrap {
        padding: 0 32*$px 24*$px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .icon-location {
            display: block;
            width: 22*$px;
            height: 24*$px;
        }

        .icon-arrow {
            display: block;
            width: 18*$px;
            height: 18*$px;
        }

        >p {
            margin: 0 8*$px;
            font-size: 14*$px;
            font-family: PingFangSC-Light, PingFang SC;
            font-weight: 300;
            color: #999999;
            line-height: 20*$px;
            flex: 1;
        }
    }

    .detail-pic-wrap {
        height: 93*$px;
        margin: 0 24*$px;

        >img {
            width: 107*$px;
            height: 93*$px;
            border-radius: 4*$px;
        }
    }

    .detail-parking-desc {
        font-size: 16*$px;
        font-family: PingFangSC-Medium, PingFang SC;
        color: #333333;
        line-height: 24*$px;
        text-indent: 24*$px;
        padding: 4*$px 0;
    }

    .go-location {
        width: 327*$px;
        height: 49*$px;
        background: #FF843A;
        border-radius: 25*$px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18*$px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 25*$px;
        margin: 36*$px auto;
    }
}
</style>
<style>
.amap-icon img {
    width: 30px;
    height: 30px;
}

.amap-overlay-text-container {
    font-size: 0.4rem;
}

.amap-logo,
.amap-copyright {
    display: none !important;
}

.amap-geo {
    display: none;
}
</style>